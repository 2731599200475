// NOTE: font-family
// ;; thsarabun
@font-face {
  font-family: 'THSarabunNew';
  src: url('assets/fonts/THSarabunNew.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewBold';
  src: url('assets/fonts/THSarabunNewBold.ttf');
  font-weight: bold;
}

// ;; chulabhornlikit
// @font-face {
//   font-family: 'ChulabhornLikitText-Light';
//   src: url('assets/fonts/ChulabhornLikitText-Light.ttf');
// }

// @font-face {
//   font-family: 'ChulabhornLikitText-Bold';
//   src: url('assets/fonts/ChulabhornLikitText-Bold.ttf');
// }

html {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

body,
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
}

h3,
th {
  font-family: 'THSarabunNewBold' !important;
  font-size: x-large !important;
  color: #0b2545;
}

.ui.table thead th {
  color: #0b2545 !important;
}

h1,
h2 {
  font-family: 'THSarabunNewBold' !important;
  font-size: xx-large !important;
}

label,
td {
  font-family: 'THSarabunNew' !important;
  font-size: x-large !important;
  color: #0b2545;
}

.ui.form .field > label {
  color: #0b2545 !important;
}

p,
a {
  font-family: 'THSarabunNew';
  font-size: large;
}

body {
  margin: 0;
  font-family: 'THSarabunNew';
  overflow-x: hidden;
  min-width: 320px;
  background: #fff;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
}

code {
  font-family: 'THSarabunNew';
}

.ui[class*='vertically divided'].grid > .row:before {
  box-shadow: none !important;
}

.swal-text,
.swal-button {
  font-family: 'THSarabunNew';
  font-size: 18pt;
}

.swal-button {
  padding: 6px 24px;
}

*,
:after,
:before {
  box-sizing: inherit;
}

p {
  line-height: 1.4285em;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'THSarabunNew' !important;
  font-size: 24px !important;
  line-height: 1.15 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.MuiButton-root {
  min-width: max-content;
}

/* textarea {
  width: 34.8cm !important;
  height: 258px !important;
  padding-left: 1.6in !important;
  padding-right: 0.8in !important;
  font-family: 'THSarabunNew' !important;
  font-size: 19.2pt !important;
  line-height: 1.15 !important;
  color: rgba(0, 0, 0, 0.87) !important;
} */

.swal-button,
.swal-text {
  font-family: 'THSarabunNew' !important;
  font-size: 18pt !important;
}

.MuiPickersDay-day {
  color: rgba(0, 0, 0, 0.87) !important;
}

.MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

//// scroll bar like apple

::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}
::-webkit-scrollbar:hover {
  background-color: transparent;
  // width: 26px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
  // width: 16px;
}
::-webkit-scrollbar-track:hover {
  background-color: transparent;
  // width: 26px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  // width: 16px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #babac0;
  border-radius: 16px;
  // width: 26px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
img.sc-gsWcmt.eFYqYw {
  cursor: pointer;
}
.btn-onAdd {
  text-align: center;
  font-size: 100px;
}
.sc-jNnpgg.bUxhWr {
  padding: 0;
  height: 0;
}

li.sc-iTVJFM.HFTrE.selected {
  background: #8da9cd;
  color: white;
}

.sc-gsWcmt.jSqLhQ {
  cursor: pointer;
}

img.sc-uxdHp.nRNbs {
  cursor: pointer;
  font-size: 20px;
}
// button{
//   height: auto;
// }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.sc-ksluID.dYuaGb.btn-drug.Mui-disabled.Mui-disabled {
  width: auto;
}
svg.MuiSvgIcon-root.icon-modal {
  color: #0b2545;
  font-size: 40px;
  float: right;
  margin-top: -18%;
  padding-left: -11%;
  cursor: pointer;
}
.input-modal {
  width: 85%;
}
span.text-insert {
  float: right;
  font-size: 25px;
  z-index: 1;
  display: block;
  margin-top: -90px;
  font-weight: bold;
  color: #0b2545;
  margin-right: 5px;
}
.span-SearchReporForm {
  margin-left: 40px;
  margin-right: 20px;
  padding-bottom: 0px;
}
#input-search {
  padding-right: 10px;
}
.sc-amkrK.hgPRGv {
  padding: 10px;
  margin-top: 20px;
}
.sc-iemWCZ.cpiGqF.sc-dPaNzc.text-mainsection {
  width: 100%;
}
.btn-select-rank {
  float: right;
}
.password-hide {
  width: 90% !important;
}
.Iconsee {
  margin: 8px !important;
}
.IconHide {
  margin-top: 6% !important;
  margin-left: 5px;
}
.Hide-Password {
  margin-top: 10% !important;
  margin: 10px;
}
.Forgot-Password {
  margin-top: 80% !important;
  margin: 10px;
}
.dataTable-suspect {
  margin-top: 1em;
}

.MuiTypography-root {
  font-family: 'THSarabunNew';
  font-size: 18pt;
}

.ant-picker-dropdown {
  font-size: 18pt;
}

mark {
  background-color: #ffb731;
}

// @font-face {
//   font-family: 'THSarabunNew';
//   src: url('assets/fonts/ChulabhornLikitText-Light.ttf');
//   font-weight: bold;
// }

// .bodyPDF h5 {
//   font-family: 'THSarabunNewBold';
//   font-size: 20px;
// }

// .bodyPDF p {
//   font-family: 'THSarabunNew';
//   font-size: 20px;
// }

// #bodyPDF {
//   padding-right: 20px;
// }

// .bodyPDF table {
//   border-collapse: collapse;
//   width: 100%;
// }

// .bodyPDF table td {
//   border: 1px solid black;
// }

// .bodyPDF .underline {
//   text-decoration: underline;
// }

// .bodyPDF .rowNumber {
//   list-style-type: none;
//   padding: 0;
// }

// .bodyPDF .caseFileHeader {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 20px;
//   flex-direction: column;
// }

// .bodyPDF .nsbImg {
//   width: 100px;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
// }

// .bodyPDF .caseFileDetail {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 20px;
//   flex-direction: column;
// }

// .bodyPDF .footerTable {
//   width: 100%;
// }

// .bodyPDF .investigationSection {
//   text-align: center;
// }

// // .bodyPDF .investigationImages {
// //   /*display: grid;
// //   grid-template-columns: repeat(2, 1fr);
// //   row-gap: 20px;*/
// // }

// .bodyPDF .investigationImage {
//   /*width: 40vw;
//   height: auto;*/
//   width: 80%;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
// }

// // .bodyPDF * {
// //   box-sizing: border-box;
// //   -moz-box-sizing: border-box;
// //   font-family: 'THSarabunNew';
// // }

// // .bodyPDF body {
// //   font-size: 20px;
// // }

// .bodyPDF .page-number {
//   font-size: 16px;
// }

// .bodyPDF /*span, p{
//     font-size: 1.2em;
//   }*/

// .bodyPDF .page {
//   width: 21cm;
//   min-height: 29.7cm;
//   max-height: 29.7cm;
//   padding: 1cm;
//   margin: 1cm auto;
//   border: 1px solid #000;
// }

// @page {
//   size: A4;
//   margin: 19mm 9mm 15mm 15mm;
// }

// @media print {
//   .bodyPDF .page {
//     border: initial;
//   }
//   body {
//     margin-left: 5mm;
//     margin-right: 5mm;
//   }
// }

// .bodyPDF .border-top {
//   border: 1px solid #000;
//   padding: 5px 15px;
//   width: max-content;
//   font-size: 16px;
//   margin-left: auto;
//   margin-right: 0;
//   display: block;
//   font-family: 'ChulabhornLikitText-Light';
// }

// .bodyPDF .inner-border {
//   width: 100%;
//   padding: 3px 0;
// }

// .bodyPDF .inner-border span {
//   display: inline-block;
// }

// .bodyPDF .inner-border p {
//   display: inline-block;
//   /*  border-bottom: 1px dotted #000;*/
//   margin: 0;
//   line-height: 14px;
//   height: 14px;
//   width: 80px;
// }

// .bodyPDF .border-top .inner-border:first-child p {
//   width: 83px;
// }

// .bodyPDF .border-top .inner-border:last-child p {
//   width: 115px;
// }

// .bodyPDF .header-title {
//   text-decoration: underline;
//   text-align: center;
//   margin-top: 2rem;
//   font-size: 23px !important;
//   font-family: 'ChulabhornLikitText-Bold' !important;
// }

// //header
// //สถานที่ทำการบันทึก , วัน/เดือนปี/เวลาที่จับกุม... , วัน/เดือนปี/เวลาที่บันทึก... ,
// //สถานที่จับกุม/ตรวจยึด , เจ้าพนักงานตำรวจ , ได้ร่วมกันจับกุมตัว... , ตำแหน่งที่พบของกลาง... ,
// //โดยกล่าวหาว่า...ผู้ถูกจับทราบข้อกล่าวหาและสิทธิของผู้ถูกจับจับแล้วใล้ให้การ... , ได้ร่วมกันจับกุมตัว
// .bodyPDF .text-detail .header-text {
//   text-indent: 2em;
//   text-decoration: underline;
//   font-weight: bold;
//   font-family: 'ChulabhornLikitText-Bold';
// }

// .bodyPDF .text-detail .header-text span {
//   text-indent: 2em;
//   text-decoration: underline;
//   font-weight: bold;
//   font-family: 'ChulabhornLikitText-Bold';
// }

// //content
// //สถานที่ทำการบันทึก , วัน/เดือนปี/เวลาที่จับกุม... , วัน/เดือนปี/เวลาที่บันทึก... ,
// //สถานที่จับกุม/ตรวจยึด , เจ้าพนักงานตำรวจ , ได้ร่วมกันจับกุมตัว... , ตำแหน่งที่พบของกลาง... ,
// //โดยกล่าวหาว่า...ผู้ถูกจับทราบข้อกล่าวหาและสิทธิของผู้ถูกจับจับแล้วใล้ให้การ... , ได้ร่วมกันจับกุมตัว
// .bodyPDF .text-detail .desc-text {
//   margin: 0;
//   margin-bottom: 4px;
//   line-height: 1.9em;
//   text-align: justify;
//   font-family: 'ChulabhornLikitText-Light';
// }

// .bodyPDF .text-detail > span:nth-of-type(1):not(.header-text) {
//   font-size: 18px;
//   margin: 0;
//   margin-bottom: 4px;
//   line-height: 1.9em;
//   text-align: justify;
//   font-family: 'ChulabhornLikitText-Light';
// }

// //พฤติการณ์
// #circumstances {
//   margin: 0;
//   margin-bottom: 4px;
//   line-height: 1.5em;
//   text-align: justify;
//   font-family: 'ChulabhornLikitText-Light';
// }

// #officerReport {
//   text-indent: 2em;
//   text-decoration: underline;
//   font-weight: bold;
//   font-family: 'ChulabhornLikitText-Bold';
// }

// .bodyPDF .text-detail .desc-text span:not(.header-text) {
//   text-indent: 2em;
//   display: block;
//   text-align: justify;
//   font-family: 'ChulabhornLikitText-Light';
// }

// .bodyPDF .sign-bottom {
//   font-size: 18px;
//   width: 100%;
//   // padding: 5px 15px;
//   width: 100%;
//   // margin-left: 100px;
//   font-family: 'ChulabhornLikitText-Light';
// }

// .bodyPDF .sign-bottom .inner-sign {
//   width: 100%;
//   padding: 3px 0;
// }

// .bodyPDF .sign-bottom .inner-sign {
//   width: 100%;
//   padding: 5px 0;
// }

// .bodyPDF .sign-bottom .inner-sign span {
//   display: inline-block;
// }

// .bodyPDF .sign-bottom .inner-sign:not(.multi-sign) > span {
//   width: 100%;
// }

// .bodyPDF .sign-bottom .inner-sign .text-sign-name {
//   text-indent: 4em;
// }

// .bodyPDF .sign-bottom .inner-sign p {
//   display: inline-block;
//   /*  border-bottom: 1px dotted #000;*/
//   margin: 0;
//   line-height: 14px;
//   height: 14px;
//   width: 240px;
// }

// .bodyPDF .sign-bottom .inner-sign.multi-sign {
//   padding: 8px 0;
// }

// .bodyPDF .sign-bottom .multi-sign.first-child {
//   margin-top: 30px;
// }

// .bodyPDF .sign-bottom .inner-sign.multi-sign p {
//   width: 110px;
//   border-bottom: none;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign {
//   margin-top: 15px;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign-name {
//   margin-bottom: 15px;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign,
// .sign-bottom .inner-sign.master-sign-name {
//   padding-left: 60px;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign p {
//   width: 110px;
//   border-bottom: none;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign .title-sign {
//   text-indent: 12em;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign .title-sign span {
//   width: 450px;
//   text-align: right;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign .title-sign {
//   text-indent: 0em;
//   width: 100%;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign-name .text-sign-name {
//   text-indent: 4em;
//   width: 100%;
// }

// .bodyPDF .sign-bottom .inner-sign.master-sign-name .text-sign-detail {
//   text-indent: 0em;
// }

// //อนึ่ง
// #once {
//   font-family: 'ChulabhornLikitText-Light';
//   display: block;
//   text-align: justify;
// }

// .bodyPDF .text-detail.inner-choice .desc-text span:first-child {
//   display: inline-block;
//   border-bottom: 1px dotted #000;
//   line-height: 14px;
//   height: 14px;
//   width: 50%;
// }

// // .bodyPDF .text-detail.inner-choice .desc-text span:nth-child(2) {
// //   font-size: 26px;
// // }

// .bodyPDF .text-detail.inner-choice .desc-text span {
//   display: inline;
// }

// .bodyPDF .text-detail.inner-choice .desc-text span:not(:last-child) {
//   margin-right: 5px;
// }

// .bodyPDF .desc-text-detail span:not(.header-text) {
//   display: block;
//   text-indent: 2em;
//   font-family: 'ChulabhornLikitText-Light';
// }

// .bodyPDF .desc-text-detail-rule span:not(.header-text) {
//   display: block;
//   text-indent: 2rem;
//   font-family: 'ChulabhornLikitText-Light';
// }

// .bodyPDF .desc-text .desc-text-rule span {
//   font-family: 'ChulabhornLikitText-Light';
// }

// .bodyPDF .desc-text-detail .property-section {
//   display: block;
//   font-family: 'ChulabhornLikitText-Light';
// }

// .bodyPDF .desc-text-detail .property-panel {
//   display: block;
//   text-indent: 2rem;
// }
// .input-arrest{
//   padding: auto;
// }

// @media print {
//   .watermark {
//     position: fixed;
//     opacity: 0.5;
//     top: 20vh;
//     left: 10vh;
//     width: 70%;
//     height: auto;
//   }

//   // body {
//   //   counter-reset: page;
//   //   // page-break-inside: auto;
//   // }

//   // .print-footer {
//   //   position: fixed;
//   //   top: 0;
//   //   left: 0;
//   // }

//   // .print-footer:after {
//   //   position: fixed;
//   //   top: 0;
//   //   left: 0;
//   //   counter-increment: page;
//   //   content: 'P ' counter(page);
//   // }
// }

// NOTE: CDR
.btn-search-cdr {
  margin-left: 6%;
  margin-top: 0.5rem;
}

input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
  background-color: white;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  background-color: white;
}

.select-cdr {
  margin: auto;
}

.style-cdr {
  // margin-top: 40px;
}

// input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
//   height: 22px;
// }

.modal-telephone {
  width: auto;
}

// .sc-cTJkRt.dnYkoC {
//   margin: 0.3em;
// }

// .sc-bBjRSN.dHGgoM {
//   height: auto;
// }

.swal-modal {
  // min-height: 25%;
  min-height: 20vh;
}

.swalDetectScreen {
  width: 150vh;
  height: 70vh;
}

.swal-button--violet {
  background-color: mediumslateblue;
}
