/* ZoomCharts SDK version 1.16.5 (c815cfd8b472475fdca6f5fdf81f200303706965) */
.DVSL-container,
.DVSL-interaction {
  -webkit-tap-highlight-color: transparent;
}
.DVSL-font-height,
.DVSL-resize-triggers {
  visibility: hidden;
}
.DVSL-bar-left,
.DVSL-bar-right {
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
}
.DVSL-bar-left > li,
.DVSL-bar-right > li {
  position: relative;
  list-style: none !important;
  min-height: 32px;
  float: left;
}
.DVSL-bar-left.DVSL-bar-vertical > li {
  clear: left;
}
.DVSL-bar-right.DVSL-bar-vertical > li {
  float: right;
  clear: right;
}
.DVSL-bar-left > li a,
.DVSL-bar-right > li a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  height: 32px;
  line-height: 32px;
  text-decoration: none;
}
.DVSL-bar-left li a p,
.DVSL-bar-right li a p {
  background-image: url(sprite.png);
  background-repeat: no-repeat;
  padding: 0 0 0 18px;
  margin: 0;
  float: left;
  height: 37px;
  line-height: 37px;
  text-indent: 4px;
}
.DVSL-bar-bottom > li a span,
.DVSL-bar-top > li a span {
  padding: 0 12px 0 0;
  margin: 0;
  float: right;
  height: 37px;
}
.DVSL-bar-top > li a span {
  background: url(sprite.png) right 2px no-repeat;
}
.DVSL-bar-bottom > li a span {
  background: url(sprite.png) 3px 2px no-repeat;
}
.DVSL-bar-dropdown {
  z-index: 2;
  position: absolute;
  background: #fff;
  display: none;
  border: 1px solid #ccc;
}
.DVSL-bar-top.DVSL-bar-horizontal .DVSL-bar-dropdown {
  top: 32px;
}
.DVSL-bar-bottom.DVSL-bar-horizontal .DVSL-bar-dropdown {
  bottom: 32px;
}
.DVSL-bar-right.DVSL-bar-horizontal .DVSL-bar-dropdown {
  right: 0;
}
.DVSL-bar-left.DVSL-bar-horizontal .DVSL-bar-dropdown {
  left: 0;
}
.DVSL-bar-left.DVSL-bar-vertical .DVSL-bar-dropdown {
  left: 100%;
}
.DVSL-bar-right.DVSL-bar-vertical .DVSL-bar-dropdown {
  right: 100%;
}
.DVSL-bar-top.DVSL-bar-vertical .DVSL-bar-dropdown {
  top: 0;
}
.DVSL-bar-bottom.DVSL-bar-vertical .DVSL-bar-dropdown {
  bottom: 0;
}
.DVSL-bar-dropdown:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
}
.DVSL-bar-top.DVSL-bar-horizontal .DVSL-bar-dropdown:after {
  top: -7px;
  border-bottom: 7px solid #ddd;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.DVSL-bar-bottom.DVSL-bar-horizontal .DVSL-bar-dropdown:after {
  bottom: -7px;
  border-top: 7px solid #ddd;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.DVSL-bar-right.DVSL-bar-horizontal .DVSL-bar-dropdown:after {
  right: 4px;
}
.DVSL-bar-left.DVSL-bar-horizontal .DVSL-bar-dropdown:after {
  left: 6px;
}
.DVSL-bar-left.DVSL-bar-vertical .DVSL-bar-dropdown:after {
  left: -7px;
  border-right: 7px solid #ddd;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.DVSL-bar-right.DVSL-bar-vertical .DVSL-bar-dropdown:after {
  right: -7px;
  border-left: 7px solid #ddd;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.DVSL-bar-top.DVSL-bar-vertical .DVSL-bar-dropdown:after {
  top: 10px;
}
.DVSL-bar-bottom.DVSL-bar-vertical .DVSL-bar-dropdown:after {
  bottom: 5px;
}
.DVSL-bar-dropdown.DVSL-no-triangle:after {
  display: none;
}
.DVSL-bar-dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  min-width: 120px;
}
.DVSL-bar-dropdown li a {
  float: none !important;
  display: block;
  border-bottom: none;
  border-top: none;
  padding: 4px 10px;
  color: #666;
  filter: none;
  background: #fff;
  white-space: nowrap;
  cursor: pointer;
  opacity: 1;
}
div .DVSL-bar-dropdown li a.DVSL-bar-dropdown-active {
  background: #ddd;
}
div .DVSL-bar-dropdown li a.DVSL-bar-dropdown-disabled {
  color: #aaa !important;
  cursor: default;
}
div .DVSL-bar-dropdown li a.DVSL-bar-dropdown-disabled:hover {
  color: #aaa;
  background: #fff;
}
div .DVSL-bar-dropdown li a:hover {
  color: #fff;
  background: #09c;
}
.DVSL-bar-btn {
  white-space: nowrap;
  color: #333;
  float: left;
  padding: 0 7px;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.6;
}
.DVSL-bar-btn:hover {
  opacity: 1;
}
.DVSL-bar-disabled {
  cursor: default;
  opacity: 0.3;
}
.DVSL-bar-disabled:hover {
  opacity: 0.3;
}
a.DVSL-bar-btn-none p {
  padding: 0 !important;
  background-image: none !important;
}
a.DVSL-bar-btn-image p {
  background-position: 0 center;
  min-width: 7px;
}
a.DVSL-bar-btn-back p {
  background-position: -4px -704px;
}
a.DVSL-bar-btn-zoomout p {
  background-position: -4px -665px;
}
a.DVSL-bar-btn-fullscreen p {
  background-position: -4px -994px;
}
a.DVSL-bar-btn-fullscreen-active p {
  background-position: -4px -1058px;
}
a.DVSL-bar-btn-lin p {
  background-position: -4px -66px;
}
a.DVSL-bar-btn-log p {
  background-position: -4px -141px;
}
a.DVSL-bar-btn-day p {
  background-position: -4px -217px;
}
a.DVSL-bar-btn-week p {
  background-position: -4px -292px;
}
a.DVSL-bar-btn-month p {
  background-position: -4px -368px;
}
a.DVSL-bar-btn-bars p {
  background-position: -4px -445px;
}
a.DVSL-bar-btn-children p {
  background-position: -4px -860px;
}
a.DVSL-bar-btn-export p {
  background-position: -4px -928px;
}
a.DVSL-bar-btn-fit-active p {
  background-position: -4px -1123px;
}
a.DVSL-bar-btn-fit p {
  background-position: -4px -1427px;
}
a.DVSL-bar-btn-rearrange p {
  background-position: -2px -1351px;
}
a.DVSL-bar-btn-lock-all p {
  background-position: -2px -1272px;
}
a.DVSL-bar-btn-lock-all-active p {
  background-position: -2px -1194px;
}
.DVSL-suppress-default-styles {
  color: rgba(251, 64, 167, 0.2) !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}
.DVSL-container {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.DVSL-interaction {
  outline: 0;
}
.DVSL-canvas {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
}
.DVSL-menu-container {
  pointer-events: none;
}
.DVSL-menu-container.DVSL-menu-workaround {
  box-shadow: none;
}
.DVSL-menu-container > * {
  pointer-events: auto;
}
.DVSL-menu-container .DVSL-empty {
  pointer-events: none;
  font-size: 1px;
}
.DVSL-font-height {
  position: absolute !important;
  top: -999px !important;
  clear: both;
}
.DVSL-font-height div {
  padding: 0 !important;
  line-height: 1em !important;
}
.DVSL-container .DVSL-background-image {
  background: center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.DVSL-PC-background-image {
  -webkit-border-radius: 9999px;
  -moz-border-radius: 9999px;
  border-radius: 9999px;
  background-position: center center;
  position: absolute;
  overflow: hidden;
}
.DVSL-container.DVSL-gradient .DVSL-background-image,
.DVSL-container.DVSL-round .DVSL-background-image {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.DVSL-container.DVSL-gradient .DVSL-background {
  background: #fafafa;
  background: -moz-linear-gradient(top, #fafafa 0, #bababa 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fafafa), color-stop(100, #bababa));
  background: -webkit-linear-gradient(top, #fafafa 0, #bababa 100%);
  background: -o-linear-gradient(top, #fafafa 0, #bababa 100%);
  background: -ms-linear-gradient(top, #fafafa 0, #bababa 100%);
  background: linear-gradient(to bottom, #fafafa 0, #bababa 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#bababa', GradientType=0 );
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.DVSL-container.DVSL-round .DVSL-background {
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.DVSL-valueAxisInside .DVSL-valueAxis {
  background-color: rgba(255, 255, 255, 0.7);
}
.DVSL-valueAxisInside .DVSL-valueAxis.DVSL-gradient {
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.DVSL-container.DVSL-gradient .DVSL-border {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.8);
}
.DVSL-container.DVSL-flat .DVSL-border {
  box-shadow: none;
}
.DVSL-hover-right {
  left: 30px;
  top: 10px;
}
.DVSL-hover-left {
  left: 550px;
  top: 10px;
}
.DVSL-hover-center,
.DVSL-hover-left,
.DVSL-hover-right {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333;
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  padding: 5px 0;
}
.DVSL-gradient .DVSL-hover-center,
.DVSL-gradient .DVSL-hover-left,
.DVSL-gradient .DVSL-hover-right {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.DVSL-gradient .DVSL-hover-left,
.DVSL-gradient .DVSL-hover-right {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.DVSL-flat .DVSL-hover-left,
.DVSL-flat .DVSL-hover-right {
  border: 1px solid #ddd;
}
.DVSL-hover-center {
  left: 300px;
  top: 10px;
  background: rgba(45, 136, 181, 0.5);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.DVSL-hover-left em,
.DVSL-hover-right em {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  top: 50%;
  margin-top: -6px;
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
.DVSL-hover-right em {
  border-right: 6px solid #fff;
  left: -6px;
}
.DVSL-hover-left em {
  border-left: 6px solid #fff;
  right: -6px;
}
.DVSL-flat .DVSL-hover-right em {
  border-right: 6px solid #ddd;
}
.DVSL-flat .DVSL-hover-left em {
  border-left: 6px solid #ddd;
}
.DVSL-hover-left div,
.DVSL-hover-right div {
  float: left;
  padding: 5px 8px;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  margin-left: -1px;
}
.DVSL-hover-center div {
  padding: 0 6px;
}
.DVSL-hover-center div:first-child,
.DVSL-hover-left div:first-child,
.DVSL-hover-right div:first-child {
  border-left: none;
}
.DVSL-hover-center div:last-child,
.DVSL-hover-left div:last-child,
.DVSL-hover-right div:last-child {
  border-right: none;
}
.DVSL-hover-center p,
.DVSL-hover-left p,
.DVSL-hover-right p {
  padding: 0;
  margin: 0;
  font-size: 13px;
}
.DVSL-hover-center p {
  display: none;
}
.DVSL-hover-center strong,
.DVSL-hover-left strong,
.DVSL-hover-right strong {
  display: block;
  font-weight: 700;
  color: #39c;
  font-size: 28px;
  line-height: 28px;
}
.DVSL-hover-center strong {
  color: #333;
}
.DVSL-gradient .DVSL-hover-center strong {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.DVSL-hover-center small,
.DVSL-hover-left small,
.DVSL-hover-right small {
  font-size: 12px;
  color: #666;
}
.DVSL-hover-center small {
  color: #fff;
}
.DVSL-resizer {
  position: absolute;
  display: none;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc;
}
.DVSL-gradient .DVSL-resizer {
  height: 8px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAADC0AAAAICAMAAABQvxTqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURUxpcQQEBAQEBAQEBBEREdzc3O7u7gQEBO7u7tvb29LS0vz8/OXl5fHx8aGhoebm5skxTVUAAAAIdFJOUwARMQohpfE1b/FKfQAAAIZJREFUeNrt2UEOgjAURdFfWqCKhf3v1sSZUQdMmlTOuQt4C3gBwCXl1EUOAABgLKlMXZQUAADASHKZ29ZBm4t3AcAXDcBQyrx1MpcEwKByRC4TAFezLq3W2r5W236i+rvXxLJOAAyq5EgVgOs5Hp0cFYBhpcg3AHiznwiAP5Yj7gAAAJ/iCZp5fSq+kErxAAAAAElFTkSuQmCC)
    top center;
  border-bottom: none;
  border-top: none;
}
.DVSL-Menu,
.DVSL-info-center,
.DVSL-info-left,
.DVSL-info-right {
  background: #efefef;
  -moz-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.6);
  font-family: Arial, Helvetica, sans-serif;
}
.DVSL-info-center b,
.DVSL-info-left b,
.DVSL-info-right b {
  display: block;
  padding: 4px 9px 0;
  font-weight: 400;
}
.DVSL-info-center,
.DVSL-info-left,
.DVSL-info-right {
  position: absolute;
  -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.6);
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border-bottom: 1px solid #fff;
  font-size: 13px;
  color: #333;
  padding-bottom: 2px;
  z-index: 1;
}
.DVSL-info-center strong,
.DVSL-info-left strong,
.DVSL-info-right strong {
  padding: 7px 10px;
  background: #fff;
  display: block;
  border-bottom: 1px solid #ddd;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
}
.DVSL-info-center strong small,
.DVSL-info-left strong small,
.DVSL-info-right strong small {
  display: inline-block;
  padding-left: 5px;
  font-size: 12px;
  color: #666;
}
.DVSL-info-center h3,
.DVSL-info-left h3,
.DVSL-info-right h3 {
  padding: 6px 7px 5px;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.DVSL-info-center table,
.DVSL-info-left table,
.DVSL-info-right table {
  padding: 0;
  margin: 0;
  font-size: 12px;
  width: 100%;
}
.DVSL-info-center tr,
.DVSL-info-left tr,
.DVSL-info-right tr {
  padding: 0;
  margin: 0;
}
.DVSL-info-center tr:nth-child(even),
.DVSL-info-left tr:nth-child(even),
.DVSL-info-right tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.5);
}
.DVSL-info-center td,
.DVSL-info-left td,
.DVSL-info-right td {
  border: 0 solid transparent;
  position: relative;
  padding: 3px 5px;
  color: #666;
  text-align: right;
}
.DVSL-info-left em,
.DVSL-info-right em {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  top: 16px;
  margin-top: -6px;
}
.DVSL-info-left td,
.DVSL-info-right td {
  border-left-width: 3px;
}
.DVSL-info-center td:nth-child(even),
.DVSL-info-left td:nth-child(even),
.DVSL-info-right td:nth-child(even) {
  padding-right: 1px;
}
.DVSL-info-center td:nth-child(odd),
.DVSL-info-left td:nth-child(odd),
.DVSL-info-right td:nth-child(odd) {
  padding-left: 1px;
}
.DVSL-info-center td:first-child,
.DVSL-info-left td:first-child,
.DVSL-info-right td:first-child {
  padding-left: 5px;
  text-align: left;
}
.DVSL-info-center td:last-child,
.DVSL-info-left td:last-child,
.DVSL-info-right td:last-child {
  padding-right: 5px;
  white-space: nowrap;
}
.DVSL-info-left em,
.DVSL-info-right em {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
.DVSL-info-right em {
  border-right: 6px solid #fff;
  left: -6px;
}
.DVSL-info-left em {
  border-left: 6px solid #fff;
  right: -6px;
}
.DVSL-Menu {
  line-height: 140%;
  -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, 0.6);
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.6);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border-bottom: 1px solid #fff;
  font-size: 13px;
  color: #333;
  max-width: 600px;
  z-index: 10000;
}
.DVSL-Menu strong {
  padding: 15px 35px 14px 16px;
  margin: -6px -32px -6px -6px;
  background: #fff;
  display: block;
  border-bottom: 1px solid #ddd;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.DVSL-Menu ul {
  float: left;
  padding: 16px;
  margin: 0;
  max-width: 300px;
}
.DVSL-Menu.NC-with-logo ul {
  width: 270px;
}
.DVSL-Menu li {
  list-style: none;
  padding: 1px 0 0 80px;
  clear: both;
  font-size: 12px;
  color: #333;
}
.DVSL-Menu li span {
  float: left;
  margin-left: -80px;
  color: #666;
}
.DVSL-Menu img {
  width: 180px;
  height: auto;
  float: right;
  padding: 16px 16px 12px 0;
}
.DVSL-Menu .clear {
  clear: both;
}
.DVSL-Menu-details {
  position: relative;
  padding: 16px 16px 0;
  min-width: 100px;
}
.DVSL-Menu-details small {
  position: absolute;
  right: 16px;
  top: -8px;
  font-size: 11px;
  color: #999;
  outline: 0;
}
.DVSL-Menu-details small:hover {
  color: #333;
  cursor: pointer;
}
.DVSL-Menu-details pre {
  padding: 12px;
  background: #fff;
  margin-top: -9px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.7);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.7);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  white-space: pre-wrap;
  display: none;
}
.DVSL-Menu nav {
  background: #e2e2e2;
  display: block;
  padding: 8px 8px 3px;
  -webkit-box-shadow: inset 0 2px 2px -1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 2px 2px -1px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 2px 2px -1px rgba(0, 0, 0, 0.3);
  border-top: 1px solid #fff;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-bottomright: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.DVSL-Menu nav:empty {
  display: none;
}
.DVSL-Menu nav a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 10px;
  color: #333;
  display: inline-block;
  padding: 3px 2px 2px 22px;
  background-repeat: no-repeat;
  opacity: 0.7;
  position: relative;
}
.DVSL-Menu nav a:hover {
  color: #000;
  opacity: 1;
}
.DVSL-Menu nav a:hover:after {
  content: '';
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  height: 2px;
  background: #09c;
}
.DVSL-Menu b {
  position: absolute;
  left: 16px;
  top: -8px;
  font-size: 11px;
  color: #fff;
  background: #c00;
  padding: 0 4px;
  font-weight: 400;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  z-index: 1000;
}
.DVSL-Menu-title {
  padding: 6px 32px 4px 6px;
  min-height: 20px;
}
.DVSL-Menu-close {
  position: absolute;
  top: 5px;
  right: 5px;
  text-decoration: none;
  color: #ccc;
  border: 1px solid #ccc;
  width: 19px;
  height: 19px;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  font-family: Verdana, Geneva, sans-serif;
  cursor: pointer;
}
.DVSL-Menu-close::after {
  font-family: sans-serif;
  font-size: 14px;
  content: 'X';
  vertical-align: middle;
}
.DVSL-Menu-close:hover {
  color: #333;
  border-color: #666;
}
.DVSL-contract-trigger:before,
.DVSL-resize-triggers,
.DVSL-resize-triggers > div {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.DVSL-resize-triggers > div {
  background: #eee;
  overflow: hidden;
}
.DVSL-contract-trigger:before {
  width: 200%;
  height: 200%;
}
.DVSL-fullscreen {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  background: #fff;
  width: auto !important;
  height: auto !important;
}
.DVSL-dark .DVSL-bar-dropdown {
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.6);
  background-color: rgba(22, 22, 22, 0.5);
  border: none;
}
.DVSL-dark .DVSL-bar-dropdown a {
  background-color: rgba(22, 22, 22, 1);
}
.DVSL-dark .DVSL-bar-dropdown ul li {
  display: block;
  width: 100%;
}
.DVSL-dark .DVSL-info-center,
.DVSL-dark .DVSL-info-left,
.DVSL-dark .DVSL-info-right {
  border-radius: 0;
  background-color: #000;
  border-bottom: 0;
  width: 250px;
}
.DVSL-PieChart.DVSL-dark .DVSL-info-center,
.DVSL-PieChart.DVSL-dark .DVSL-info-left,
.DVSL-PieChart.DVSL-dark .DVSL-info-right {
  border-radius: 6px;
  background-color: #efefef;
  border-bottom: 1px solid #fff;
}
.DVSL-dark .DVSL-bar-left > li a,
.DVSL-dark .DVSL-bar-right > li a {
  color: #ccc;
  height: 37px;
}
.DVSL-dark .DVSL-bar-left > li a:hover,
.DVSL-dark .DVSL-bar-right > li a:hover {
  opacity: 0.8;
}
.DVSL-dark .DVSL-info-center strong,
.DVSL-dark .DVSL-info-left strong,
.DVSL-dark .DVSL-info-right strong {
  background-color: #333;
  color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #636363;
}
.DVSL-dark .DVSL-info-right em {
  border-right: 6px solid #333;
}
.DVSL-dark .DVSL-info-left em {
  border-left: 6px solid #333;
}
.DVSL-dark .DVSL-info-center small,
.DVSL-dark .DVSL-info-left small,
.DVSL-dark .DVSL-info-right small {
  background-color: #333;
  color: #a8a7a8;
}
.DVSL-dark .DVSL-info-center tr:nth-child(even),
.DVSL-dark .DVSL-info-left tr:nth-child(even),
.DVSL-dark .DVSL-info-right tr:nth-child(even) {
  background-color: #333;
}
.DVSL-dark .DVSL-info-center tr:nth-child(odd),
.DVSL-dark .DVSL-info-left tr:nth-child(odd),
.DVSL-dark .DVSL-info-right tr:nth-child(odd) {
  background-color: #000;
}
.DVSL-dark .DVSL-info-center td:nth-child(2),
.DVSL-dark .DVSL-info-left td:nth-child(2),
.DVSL-dark .DVSL-info-right td:nth-child(2) {
  color: #a8a7a8;
}
.DVSL-dark .DVSL-NC-zoom {
  background: #525252;
}
a.DVSL-NC-button-collapse,
a.DVSL-NC-button-expand,
a.DVSL-NC-button-focus,
a.DVSL-NC-button-hide,
a.DVSL-NC-button-lock,
a.DVSL-NC-button-unfocus,
a.DVSL-NC-button-unlock {
  background-image: url(netchart-sprite.png);
}
.DVSL-dark .DVSL-bar-top > li a span {
  background-position: -12px -34px;
}
.DVSL-dark a.DVSL-bar-btn-lin p {
  background-position: -4px -103px;
}
.DVSL-dark a.DVSL-bar-btn-log p {
  background-position: -4px -178px;
}
.DVSL-dark a.DVSL-bar-btn-month p {
  background-position: -4px -406px;
}
.DVSL-dark a.DVSL-bar-btn-bars p {
  background-position: -4px -483px;
}
.DVSL-dark a.DVSL-bar-btn-zoomout p {
  background-position: -4px -740px;
}
.DVSL-dark a.DVSL-bar-btn-back p {
  background-position: -4px -780px;
}
.DVSL-dark a.DVSL-bar-btn-export p {
  background-position: -4px -963px;
}
.DVSL-dark a.DVSL-bar-btn-lock-all p {
  background-position: -4px -1310px;
}
.DVSL-dark a.DVSL-bar-btn-lock-all-active p {
  background-position: -4px -1232px;
}
.DVSL-dark a.DVSL-bar-btn-fit-active p {
  background-position: -4px -1155px;
}
.DVSL-dark a.DVSL-bar-btn-fit p {
  background-position: -4px -1460px;
}
.DVSL-dark a.DVSL-bar-btn-rearrange p {
  background-position: -4px -1390px;
}
.DVSL-dark a.DVSL-bar-btn-fullscreen p {
  background-position: -4px -1025px;
}
.DVSL-dark a.DVSL-bar-btn-fullscreen-active p {
  background-position: -4px -1090px;
}
a.DVSL-NC-button-expand {
  background-position: 0 0;
}
a.DVSL-NC-button-collapse {
  background-position: 0 -35px;
}
a.DVSL-NC-button-focus {
  background-position: 0 -135px;
}
a.DVSL-NC-button-unfocus {
  background-position: 0 -169px;
}
a.DVSL-NC-button-lock {
  background-position: 2px -103px;
}
a.DVSL-NC-button-unlock {
  background-position: 0 -69px;
}
a.DVSL-NC-button-hide {
  background-position: 1px -202px;
}
.DVSL-NC-zoom {
  position: relative;
  display: block;
  left: 15px;
  width: 4px;
  height: 100px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: #ddd;
}
.DVSL-NC-zoom em {
  position: absolute;
  left: -9px;
  width: 22px;
  height: 20px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.01) 0, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.2) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(0, 0, 0, 0.01)),
    color-stop(1%, rgba(0, 0, 0, 0.01)),
    color-stop(100%, rgba(0, 0, 0, 0.2))
  );
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.01) 0, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.2) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.01) 0, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.2) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.01) 0, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.2) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.01) 0, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03000000', endColorstr='#33000000', GradientType=0 );
  background-color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.DVSL-NC-zoom em:active,
.DVSL-NC-zoom em:hover {
  background-color: #ddd;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid transparent;
}
.DVSL-container.DVSL-gradient .DVSL-TC-timeAxis {
  background: #cacaca;
  background: -moz-linear-gradient(top, #cacaca 0, #989898 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #cacaca), color-stop(100%, #989898));
  background: -webkit-linear-gradient(top, #cacaca 0, #989898 100%);
  background: -o-linear-gradient(top, #cacaca 0, #989898 100%);
  background: -ms-linear-gradient(top, #cacaca 0, #989898 100%);
  background: linear-gradient(to bottom, #cacaca 0, #989898 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cacaca', endColorstr='#989898', GradientType=0 );
  border-top: 1px solid #c9c9c9;
  -webkit-box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.5);
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.DVSL-valueAxisInside .DVSL-TC-timeAxis.DVSL-gradient {
  -webkit-box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.5);
}
